@font-face {
  font-family: 'Bluey';
  src: url('bfont.ttf') format('truetype');
  font-display: swap;
}
html {
  background-color: #90d7fb;
}

html.dark {
  background-color: #2b2c40;
}

.blueyfont {
  font-family: Bluey, sans-serif;
}

.blueyblue {
  color: #2c86ff;
}

.bingobrown {
  color: #fd9c6e;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.banner {
  padding: 3px;
  color: #063068;
  font-style: italic;
  font-size: smaller;
}

.modal-buyme-link-promo {
  margin: 0 auto;
}
